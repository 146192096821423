//====================================================
//  Function: Slider
//====================================================
function componentSwiper() {
  textSlider()
  modalSlider()
  benefitsSlider()
}

function benefitsSlider() {
  if ($('.js-slider-benefits').length) {
    var inst = $('.js-slider-benefits'),
      swiperBlock = inst.closest('.js-slider-group')

    var mySwiper = new Swiper(inst, {
      slidesPerView: 'auto',
      spaceBetween: 0,
      lazy: true,
      loop: false,
      speed: 600,
    })
  }
}

function textSlider() {
  if ($('.js-slider-text-animated').length) {
    var inst = $('.js-slider-text-animated'),
      swiperBlock = inst.closest('.js-slider-group')

    var mySwiper = new Swiper(inst, {
      slidesPerView: 1,
      spaceBetween: 0,
      lazy: true,
      loop: true,
      speed: 600,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 1500,
        disableOnInteraction: true,
      },
    })
  }
}

var swiperModal
function modalSlider() {
  if ($('.js-slider-modal').length) {
    var inst = $('.js-slider-modal'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperPagination = swiperBlock.find('.js-swiper-pagination'),
      swiperArrowPrev = swiperBlock.find('.js-swiper-arrow-prev'),
      swiperArrowNext = swiperBlock.find('.js-swiper-arrow-next')

    if ($(window).width < 992) {
      var autoH = true
    } else {
      var autoH = true
    }

    swiperModal = new Swiper(inst, {
      slidesPerView: 1,
      spaceBetween: 20,
      autoHeight: autoH,
      lazy: true,
      loop: true,
      noSwiping: true,
      noSwipingClass: 'swiper-slide',
      speed: 600,
      pagination: {
        clickable: true,
        el: swiperPagination,
        type: 'fraction',
      },
      navigation: {
        nextEl: swiperArrowNext,
        prevEl: swiperArrowPrev,
      },
    })

    swiperModal.on('slideChange', function () {
      var id = swiperModal.activeIndex
      var number = $('.js-modal .js-select option:last-child').val()
      if (id > number) {
        id = $('.js-modal .js-select option:first-child').val()
      }
      if (id == 0) {
        id = $('.js-modal .js-select option:last-child').val()
      }
      $('.js-modal .js-select').val(id)
      $('.js-modal .js-select').trigger('change')

      if ($(window).width() < 992) {
        $('.modal')
          .stop()
          .animate({
            scrollTop: $('body').offset().top,
          })
      }
    })
  }
}
