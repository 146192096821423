function componentReferencies() {
  var referenceGallery = $('.referencie-gallery-group')
  if ($('.js-tag-link').length) {
    $('.js-tag-link').hover(
      function () {
        var inst = $(this)
        var thisId = inst.attr('data-id')
        referenceGallery.removeClass('is-default-active')
        $('.js-tab-content-item').removeClass(classIsActive)
        $('.js-tab-content-item[data-id="' + thisId + '"]').addClass(
          classIsActive,
        )
      },
      function () {
        var inst = $(this)
        referenceGallery.addClass('is-default-active')
      },
    )

    $('.js-tabs-group .js-tab-list-item').click(function () {
      var inst = $(this)
      var thisId = inst.attr('data-id')
      var thisTabs = $(this).closest('.js-tabs-group')

      if (thisId == 'undefined') {
        return false
      }

      if (!inst.hasClass(classIsActive)) {
        thisTabs
          .find('.js-tab-list-item.' + classIsActive + '')
          .removeClass(classIsActive)
        $(this).closest('.js-tab-list-item').addClass(classIsActive)
        thisTabs
          .find('.js-tabs-content .js-tab-content-item.' + classIsActive + '')
          .removeClass(classIsActive)
        thisTabs
          .find(
            '.js-tabs-content .js-tab-content-item[data-id="' + thisId + '"]',
          )
          .addClass(classIsActive)
      }
    })
  }
}
