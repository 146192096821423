//====================================================
//  Function: Modal
//====================================================
function componentModal() {
  if ($('.service-list__item').length) {
    $('.service-list').each(function () {
      var inst = $(this)
      var counter = 0
      inst.find('.service-list__item').each(function () {
        var elem = $(this)
        if (!elem.hasClass('d-none')) {
          counter++
          elem.attr('data-id', counter)
        }
      })
    })
  }

  if ($('.js-modal-trigger').length) {
    // open modal
    $('.js-modal-trigger').on('click', function () {
      var inst = $(this)
      var modalId = inst.data('modal')

      if ($('.js-modal.is-active').length) {
        $('.js-modal.' + classIsActive + '').addClass('modal-hidden')
      } else {
        $('.js-modal.' + classIsActive + '').removeClass(classIsActive)
        $('.js-modal-overlay').addClass(classIsActive)
      }

      if (inst.hasClass('js-modal-classic')) {
        $('body').addClass('hide-modal-slider')
        $('.js-modal[data-modal=' + modalId + ']').addClass(classIsActive)

        var contentSelect = $('.section-contact .js-select').html()
        $('.js-modal .js-select').html(contentSelect)
      } else {
        var number = inst.closest('.service-list__item').attr('data-id')
        swiperModal.destroy(true, true)
        $('.js-modal .swiper-slide').remove()
        var content = inst
          .closest('.service-list')
          .find('.hidden-content')
          .html()
        var contentSelect = inst
          .closest('.service-list')
          .find('.hidden-content-select')
          .html()
        $('.js-modal .js-select').html(contentSelect)

        $('.js-modal .js-content').html(content)
        $('.js-modal[data-modal=' + modalId + ']').addClass(classIsActive)
        $(window).trigger('resize')
        modalSlider()
        swiperModal.slideTo(number)
        $('.js-modal .js-select').val(number)
        $('.js-modal .js-select').trigger('change')
      }

      $('body').addClass('is-modal-open')
      return false
    })

    // close modal
    $('.js-close-modal').on('click', function () {
      if ($('.modal-hidden').length) {
        $('.modal-hidden').removeClass('modal-hidden')
      } else {
        $('.js-modal-overlay').removeClass(classIsActive)
      }
      $(this)
        .closest('.js-modal.' + classIsActive + '')
        .removeClass(classIsActive)
      $('body').removeClass('is-modal-open')
      $('body').removeClass('hide-modal-slider')
      return false
    })

    // close modal keypress key escape
    $(document).keydown(function (event) {
      if (event.keyCode == 27 && $('.js-modal').hasClass(classIsActive)) {
        $('.js-close-modal').trigger('click')
      }
    })

    // close modal on click outside from modal box
    $(document).on('click', function (event) {
      if (!$(event.target).closest('.js-modal-body').length) {
        $('.js-close-modal').trigger('click')
      }
    })
  }
}
